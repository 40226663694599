/** @jsx jsx */
import { jsx } from "theme-ui"
import { Box, Flex } from "theme-ui"
import { Link, graphql, StaticQuery } from "gatsby"
import PropTypes from "prop-types"

// import Button from '../components/button'

import YoutubeIcon from "../images/icons/youtube-icon.svg"
import LinkedinIcon from "../images/icons/linkedin-icon.svg"
import TwitterIcon from "../images/icons/twitter-icon.svg"
import FooterLogo from "../images/icons/footer-logo-icon.svg"
import { Container } from "./item"

import styled from "@emotion/styled"

const WhiteLink = styled(Link)`
  color: white;
  text-decoration: none;
  &:visited {
    color: white;
  }
`

const StyledYoutubeIcon = (props) => (
  <YoutubeIcon
    {...props}
    sx={{
      cursor: "pointer",

      "& > path": {
        transition: "all 0.3s ease-in-out",
        fill: "white",
      },

      "&:hover > path": {
        fill: "primary",
      },
    }}
  />
)

const StyledLinkedinIcon = (props) => (
  <LinkedinIcon
    {...props}
    sx={{
      cursor: "pointer",

      "& > path": {
        transition: "all 0.3s ease-in-out",
        fill: "white",
      },

      "&:hover > path": {
        fill: "primary",
      },
    }}
  />
)

const StyledTwitterIcon = (props) => (
  <TwitterIcon
    {...props}
    sx={{
      cursor: "pointer",

      "& > path": {
        transition: "all 0.3s ease-in-out",
        fill: "white",
      },

      "&:hover > path": {
        fill: "primary",
      },
    }}
  />
)

const StyledFooterLogo = (props) => (
  <FooterLogo
    {...props}
    sx={{
      position: "absolute",
      right: 0,
      bottom: 0,
      height: "100%",
      zIndex: 1,
    }}
  />
)

const StyledLink = (props) => (
  <Link
    {...props}
    sx={{
      fontSize: 0,
      color: "white",
      textDecoration: "none",
      transition: "all 0.3s ease-in-out",
      paddingBottom: 1,

      "&:hover": {
        color: "primary",
      },
    }}
  />
)

const Title = ({ children, ...props }) => (
  <h4
    {...props}
    sx={{
      fontSize: 1,
      fontWeight: "normal",
      marginBottom: "20px",
    }}
  >
    {children}
  </h4>
)

const ThemedParagraph = (props) => (
  <p
    {...props}
    sx={{
      fontSize: 0,
      marginTop: 0,
      marginBottom: 0,
      paddingBottom: 1,
    }}
  />
)

const StyledInput = (props) => (
  <input
    {...props}
    sx={{
      background: "none",
      border: "2px solid white",
      fontSize: 2,
      paddingY: 2,
      paddingX: 3,
      marginRight: 5,
    }}
  />
)

const Footer = ({ data }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          posts: allWordpressPost(
            filter: { categories: { elemMatch: { slug: { eq: "nexprotek" } } } }
            limit: 3
          ) {
            edges {
              node {
                slug
                title
              }
            }
          }
        }
      `}
      render={(data) => (
        <Box
          as="footer"
          sx={{
            paddingTop: 4,
            paddingBottom: 4,
            px: 4,
            color: "white",
            backgroundColor: "darkGray",
            fontSize: 0,
            position: "relative",
          }}
        >
          <Container>
            <Flex
              width={"100%"}
              sx={{
                position: "relative",
                zIndex: "100",
                flexDirection: ["column", "row"],
              }}
            >
              <Flex sx={{ flexDirection: "column", flex: "1" }}>
                <Title>Business Info</Title>
                <StyledLink to={"/about"}>About</StyledLink>
                <StyledLink to={"/contact"}>Contact</StyledLink>
                {/* <StyledLink to={'/'}>Nextdura</StyledLink> */}
              </Flex>
              <Flex sx={{ flexDirection: "column", flex: "1" }}>
                <Title>Latest News</Title>
                {data.posts.edges.map((data) => (
                  <StyledLink
                    key={data.node.slug}
                    to={`/blog/${data.node.slug}`}
                  >
                    {data.node.title}
                  </StyledLink>
                ))}
              </Flex>
              <Flex sx={{ flexDirection: "column", flex: "1" }}>
                <Title>Contact Us</Title>
                <StyledLink to={"tel:1-844-228-4227"}>
                  1-844-228-4227
                </StyledLink>
                <StyledLink to={"mailto:Contact@nexprotek.com"}>
                  Contact@nexprotek.com
                </StyledLink>
                <ThemedParagraph>15207 N 75th St Suite #101</ThemedParagraph>
                <ThemedParagraph>Scottsdale, AZ 85260</ThemedParagraph>
              </Flex>
              <div sx={{ display: "flex", flexDirection: "column", flex: 2 }}>
                {/* <Title>Follow Us</Title>
            <Flex sx={{justifyContent: 'space-between', maxWidth: '150px'}}>
              <StyledYoutubeIcon />
              <StyledLinkedinIcon />
              <StyledTwitterIcon />
            </Flex> */}
              </div>
            </Flex>
            <Flex
              width={"100%"}
              sx={{
                flexDirection: ["column", "row"],
                alignItems: "flex-end",
                position: "relative",
                zIndex: "100",
              }}
            >
              <Box sx={{ flex: 3 }} mt={3}>
                {new Date().getFullYear()} © Copyrights Nexprotek ||{" "}
                <WhiteLink to="/privacy-policy">Privacy Policy</WhiteLink> ||{" "}
                <WhiteLink to="/terms-of-use">Terms &amp; Conditions</WhiteLink>
              </Box>
              {/* <Box sx={{flex: 2}}>
            <Title>Subcribe</Title>
            <StyledInput /> 
            <Button to="/" text="Submit" />
          </Box>  */}
            </Flex>
            <StyledFooterLogo />
          </Container>
        </Box>
      )}
    />
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
